export const ProjectPermissions = {
  VIEW: 'project.view',
  CREATE: 'project.create',
  MODIFY: 'project.modify'
};

export const LaunchPermissions = {
  VIEW: 'launch.view',
  ACTION: 'launch.action'
};

export const BoostPermissions = {
  VIEW: 'boost.view',
  ACTION: 'boost.action'
};

export const MonitorPermissions = {
  VIEW: 'monitor.view'
};

export const LockPermissions = {
  VIEW: 'lock.view',
  ACTION: 'lock.action'
};

export const ClosePermissions = {
  VIEW: 'close.view',
  ACTION: 'close.action'
};
