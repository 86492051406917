import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface PublicRouteProps {
  element: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { user, isAuthenticated, loading } = useSelector(
    (state: RootState) => state.auth
  );
  const location = useLocation();

  if (loading || (isAuthenticated && !user)) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return element;
  }

  return user?.roles[0].role_tag === 'page_manager' ? (
    <Navigate to="/main" state={{ from: location }} replace />
  ) : (
    <Navigate to="/account" state={{ from: location }} replace />
  );
};

export default PublicRoute;
