import { Button, Card, message, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import AddFacebookPageComponent from './addFacebookPageModal';
import { fetchFaceBookInformation } from '../../redux/facebookUserInformation/facebookUserInformationSlice';
import { RootState, useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dateTimeUTCConverter from '../../utils/dateTimeUTCConverter';
import { SorterResult } from 'antd/es/table/interface';
import { DEFAULT_PAGINATE_SIZE, ERROR_MESSAGE } from '../../constants/common';
import useSortOrder from '../../hooks/useSortOrder';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import { getImportedFacebookPages } from '../../services/facebookServices';

// interface PageResponse {
//   id: string;
//   name: string;
//   access_token: string;
//   category: string;
//   picture: Picture;
//   fan_count: number;
//   link: string;
// }

// interface Picture {
//   data: Data;
// }

// interface Data {
//   height: number;
//   is_silhouette: boolean;
//   url: string;
//   width: number;
// }

const FacebookPageManageComponent = () => {
  const [isOpenAddPage, setIsOpenAddPage] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { faceBookUserInformation, loading } = useSelector(
    (state: RootState) => state.facebookUserInformation
  );

  useEffect(() => {
    if (!faceBookUserInformation && !loading) {
      dispatch(fetchFaceBookInformation(navigate));
    }
  }, [dispatch, navigate]);

  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const paginationConfig = usePaginationConfig(total, page, setPage);
  const [fetching, setFetching] = useState<boolean>(false);

  const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
    useSortOrder<any>();

  const fetchProject = async () => {
    try {
      setFetching(true);
      const res = await getImportedFacebookPages({
        page,
        limit: DEFAULT_PAGINATE_SIZE,
        orderBy: 'createdAt',
        sortBy: 'desc',
        ...getSortParams()
      });
      const projects = res?.data?.data;
      setData(projects);
      setTotal(projects?.meta?.total);
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
      message.error(ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    fetchProject();
  }, [page, sortOrder, isOpenAddPage]);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    updateSortOrder(sorter);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'pageId',
      key: 'pageId'
      // align: 'center',
      // ...sortColumns('name'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
      // ...sortColumns('tokenSymbol'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Liked',
      dataIndex: 'fanCount',
      key: 'fanCount'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Create At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...sortColumns('createdAt'),
      render: (value: string) => dateTimeUTCConverter(value)
    }
  ];

  return (
    <Card>
      <div className="flex gap-2 items-center justify-between">
        <Title className="leading-none !mb-4 text-dark max-sm:w-full" level={3}>
          All Pages
        </Title>
        <Button type="primary" onClick={() => setIsOpenAddPage(true)}>
          Add new page
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={fetching}
        bordered
        onChange={handleTableChange}
        pagination={paginationConfig}
        rowClassName={() => 'cursor-pointer'}
      />
      <AddFacebookPageComponent
        isModalOpen={isOpenAddPage}
        setIsModalOpen={setIsOpenAddPage}
      />
    </Card>
  );
};

export default FacebookPageManageComponent;
