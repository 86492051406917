import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Descriptions, message, Row } from 'antd';
import FacebookLogin from 'react-facebook-login';
import Paragraph from 'antd/es/typography/Paragraph';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT } from '../../constants/common';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import {
  fetchFaceBookInformation,
  setFacebookUserInformation
} from '../../redux/facebookUserInformation/facebookUserInformationSlice';
import {
  connectFacebook,
  disconnectFacebook
} from '../../services/facebookServices';
import LoadingComponent from '../../components/LoadingComponent';

interface Data {
  height: number;
  is_silhouette: boolean;
  url: string;
  width: number;
}
interface Picture {
  data: Data;
}

interface FacebookLoginResponse {
  name: string;
  email: string;
  picture: Picture;
  id: string;
  userID: string;
  expiresIn: number;
  accessToken: string;
  signedRequest: string;
  graphDomain: string;
  data_access_expiration_time: number;
}

const MainComponent = () => {
  const { faceBookUserInformation, loading } = useSelector(
    (state: RootState) => state.facebookUserInformation
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    if (!faceBookUserInformation && !loading) {
      dispatch(fetchFaceBookInformation(navigate));
    }
  }, [dispatch, navigate]);

  const responseFacebook = async (response: FacebookLoginResponse) => {
    connectFB(response);
  };

  const connectFB = async (response: FacebookLoginResponse) => {
    setFetching(true);
    try {
      const data = {
        accessToken: response.accessToken,
        dataAccessExpirationAt: moment()
          .add(response.data_access_expiration_time)
          .toISOString(),
        email: response.email,
        expireAt: moment().add(response.expiresIn, 'seconds').toISOString(),
        fbId: response.id,
        name: response.name,
        avatarUrl: response.picture.data.url
      };
      await connectFacebook(data);
      setFetching(false);
      dispatch(fetchFaceBookInformation(navigate));
    } catch (err) {
      console.log(err);
      message.error('Facebook connect failed!');
      setFetching(false);
    }
  };

  const disconnectFB = async () => {
    if (!faceBookUserInformation?.id) {
      return;
    }
    setFetching(true);
    try {
      await disconnectFacebook(faceBookUserInformation?.id);
      setFetching(false);
      dispatch(setFacebookUserInformation(null));
    } catch (err) {
      console.log(err);
      message.error('Facebook disconnect failed!');
      setFetching(false);
    }
  };

  // const { user } = useSelector((state: RootState) => state.auth);

  return (
    <Card className="shadow-small">
      <LoadingComponent loading={loading}>
        {faceBookUserInformation?.id ? (
          <Row gutter={[20, 20]}>
            <Col className="text-center" md={5}>
              <Avatar size={120} src={faceBookUserInformation?.avatarUrl} />
              <div className="mt-3">{faceBookUserInformation?.name}</div>
            </Col>
            <Col md={19}>
              <Descriptions title="User Info">
                <Descriptions.Item label="Email">
                  {faceBookUserInformation?.email}
                </Descriptions.Item>
                <Descriptions.Item label="User ID">
                  {faceBookUserInformation?.id}
                </Descriptions.Item>
                <Descriptions.Item label="Expiration Time">
                  {moment(
                    faceBookUserInformation?.dataAccessExpirationAt
                  ).format(DEFAULT_DATETIME_FORMAT)}
                </Descriptions.Item>
                <Descriptions.Item label="Access Token">
                  <Paragraph ellipsis copyable>
                    {faceBookUserInformation?.accessToken}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item>
                  <Button
                    loading={fetching}
                    type="primary"
                    onClick={disconnectFB}
                    size="small"
                  >
                    Change Account
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        ) : (
          <FacebookLogin
            appId="1389699085343958"
            // autoLoad
            callback={responseFacebook}
            fields="name,email,picture"
            scope="public_profile,email,pages_show_list,pages_messaging"
            textButton="Connect with Facebook"
            cssClass="facebook-login-button"
          />
        )}
      </LoadingComponent>
    </Card>
  );
};

export default MainComponent;
