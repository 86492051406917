import React from 'react';
import { Layout, ConfigProvider, ThemeConfig } from 'antd';
import { Content } from 'antd/es/layout/layout';
import './App.scss';
import SiderComponent from './pages/Common/SiderComponent';
import AppRoutersComponent from './routers/AppRoutersComponent';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { setupInterceptors } from './axios/axiosInstance';
import HeaderComponent from './pages/Common/HeaderComponent';

const customTheme: ThemeConfig = {
  token: {
    colorBgContainer: '#fff',
    borderRadiusLG: 10
  }
};

function App() {
  const SetupInterceptors = () => {
    const navigate = useNavigate();
    setupInterceptors(store, navigate);
    return null;
  };

  return (
    <BrowserRouter>
      <Provider store={store}>
        <SetupInterceptors />
        <ConfigProvider theme={customTheme}>
          <HeaderComponent />
          <Layout className="h-[calc(100vh-80px)]">
            <SiderComponent />
            <Layout className="bg-content">
              <Content className="my-4 mx-6 p-6">
                <AppRoutersComponent />
              </Content>
            </Layout>
          </Layout>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
