import axiosInstance from '../axios/axiosInstance';
import {
  FacebookAddPageRequest,
  FacebookConnectRequest
} from '../redux/facebookUserInformation/type';

export const getFacebookConnecting = () => {
  return axiosInstance.get('/v1/fb-accounts/connecting');
};

export const connectFacebook = (data: FacebookConnectRequest) => {
  return axiosInstance.post('/v1/fb-accounts/connect', data);
};

export const disconnectFacebook = (id: string) => {
  return axiosInstance.post(`/v1/fb-accounts/disconnect/${id}`);
};

export const getFacebookPages = (params: any) => {
  return axiosInstance.get('/v1/pages', {
    params
  });
};

export const getImportedFacebookPages = (params: any) => {
  return axiosInstance.get('/v1/pages/imported-pages', {
    params
  });
};

export const addFacebookPages = (data: FacebookAddPageRequest) => {
  return axiosInstance.post('/v1/pages', data);
};
