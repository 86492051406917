import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore
} from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import permissionReducer from './permission/permissionSlice';
import facebookUserInformationReducer from './facebookUserInformation/facebookUserInformationSlice';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rootReducer = combineReducers({
  auth: authReducer,
  facebookUserInformation: facebookUserInformationReducer,
  permissions: permissionReducer
});

const store = configureStore({
  reducer: {
    auth: authReducer,
    facebookUserInformation: facebookUserInformationReducer,
    permissions: permissionReducer
  }
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
