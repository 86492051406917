/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { ERROR_MESSAGE } from '../../constants/common';
import { createAccount } from '../../services/accountService';

const AddUserComponent = ({
  isModalOpen,
  setIsModalOpen,
  fetchUser
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  fetchUser: () => void;
}) => {
  const [createForm] = Form.useForm();
  const [isSavingInitial, setIsSavingInitial] = useState<boolean>(false);

  const handleOk = () => {
    createForm.submit();
  };

  const handleCancel = () => {
    createForm.resetFields();
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      createForm.resetFields();
    }
  }, [isModalOpen]);

  const createUser = async (data: any) => {
    try {
      setIsSavingInitial(true);
      await createAccount(data);
      setIsModalOpen(false);
      fetchUser();
      message.success('Create user successfully!');
      setIsSavingInitial(false);
    } catch (error: any) {
      if (error?.response?.data?.message.includes('E10003')) {
        message.error('User already exists!');
      } else {
        message.error(ERROR_MESSAGE);
      }
      setIsSavingInitial(false);
    }
  };

  const onFinish = async (values: any) => {
    createUser(values);
  };

  return (
    <>
      <Modal
        title="Create New User"
        open={isModalOpen}
        okText="Create New User"
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          loading: isSavingInitial
        }}
      >
        <Form
          name="inittial"
          layout="vertical"
          form={createForm}
          onFinish={onFinish}
          disabled={isSavingInitial}
        >
          <Form.Item
            name="full_name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: 'Please input name!'
              }
            ]}
          >
            <Input placeholder="Input name" />
          </Form.Item>
          <Form.Item
            name="username"
            label="User Name"
            rules={[
              {
                required: true,
                message: 'Please input user name!'
              }
            ]}
          >
            <Input placeholder="Input user name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddUserComponent;
