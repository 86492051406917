/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { message, Modal, Table } from 'antd';
import { DEFAULT_PAGINATE_SIZE, ERROR_MESSAGE } from '../../constants/common';
import { SorterResult } from 'antd/es/table/interface';
import {
  addFacebookPages,
  getFacebookPages
} from '../../services/facebookServices';
import useSortOrder from '../../hooks/useSortOrder';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import OpenSvg from '../../assets/svg/open';

const AddFacebookPageComponent = ({
  isModalOpen,
  setIsModalOpen
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}) => {
  const [isSavingInitial, setIsSavingInitial] = useState<boolean>(false);
  const { faceBookUserInformation } = useSelector(
    (state: RootState) => state.facebookUserInformation
  );

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addPages = async (data: any) => {
    try {
      setIsSavingInitial(true);
      const body = {
        accessToken: data?.access_token,
        pageId: data?.id,
        fbId: faceBookUserInformation?.fbId,
        name: data?.name,
        category: data?.category,
        pictureUrl: data?.picture?.data?.url,
        linkUrl: data?.link,
        fanCount: data?.fan_count
      };
      await addFacebookPages(body);
      setIsModalOpen(false);
      message.success('Add page successfully!');
      setIsSavingInitial(false);
    } catch (error: any) {
      if (error?.response?.data?.message.includes('E10003')) {
        message.error('User already exists!');
      } else {
        message.error(ERROR_MESSAGE);
      }
      setIsSavingInitial(false);
    }
  };

  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const paginationConfig = usePaginationConfig(total, page, setPage);
  const [fetching, setFetching] = useState<boolean>(false);

  const { sortOrder, updateSortOrder, getSortParams } = useSortOrder<any>();

  const fetchProject = async () => {
    try {
      setFetching(true);
      const res = await getFacebookPages({
        page,
        limit: DEFAULT_PAGINATE_SIZE,
        orderBy: 'createdAt',
        sortBy: 'desc',
        ...getSortParams()
      });
      const projects = res?.data?.data;
      setData(projects);
      setTotal(projects?.meta?.total);
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
      message.error(ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchProject();
    }
  }, [page, sortOrder, isModalOpen]);

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    updateSortOrder(sorter);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
      // align: 'center',
      // ...sortColumns('name'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
      // ...sortColumns('tokenSymbol'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Liked',
      dataIndex: 'fan_count',
      key: 'fan_count'
      // ...sortColumns('chainId'),
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      // ...sortColumns('createdAt'),
      render: (value: string) => (
        <div
          onClick={() => {
            window.open(value, '_blank', 'noopener,noreferrer');
          }}
        >
          <OpenSvg />
        </div>
      )
    }
  ];

  return (
    <>
      <Modal
        footer={null}
        title="Add page"
        open={isModalOpen}
        onCancel={handleCancel}
        width={900}
        okButtonProps={{
          loading: isSavingInitial
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          loading={fetching}
          bordered
          onChange={handleTableChange}
          pagination={paginationConfig}
          rowClassName={() => 'cursor-pointer'}
          onRow={(record) => {
            return {
              onClick: () => {
                console.log(record?.id);
                addPages(record);
              }
            };
          }}
        />
      </Modal>
    </>
  );
};

export default AddFacebookPageComponent;
