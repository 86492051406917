/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  LockOutlined,
  UserOutlined,
  LoginOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined
} from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginFailure,
  loginStart,
  loginSuccess
} from '../../redux/auth/authSlice';
import { RootState } from '../../redux/store';
import { LoginRequest } from './type/LoginTypes';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/authenServices';

const LoginComponent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error } = useSelector((state: RootState) => state.auth);

  const onFinish = async (values: LoginRequest) => {
    dispatch(loginStart());
    try {
      const loginRes = await login(values);
      dispatch(loginSuccess({ token: loginRes?.data?.data?.access_token }));
      navigate('/');
    } catch {
      // const err = error?.response?.data?.message[0];
      dispatch(loginFailure('Incorrect username or password!'));
    }
  };

  return (
    <div className="login-screen bg-transparent">
      <div className="login-block shadow-medium">
        <div className="flex gap-3 items-center justify-center mb-4">
          <img className=" h-[80px]" src="/images/logo-fb.png" />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          {!!error && (
            <Alert
              style={{ marginBottom: '30px' }}
              message={error}
              type="error"
              showIcon
              closable
            />
          )}
          <Form.Item>
            <Button
              size="large"
              style={{ width: '100%', gap: '16px' }}
              loading={loading}
              icon={<LoginOutlined />}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginComponent;
