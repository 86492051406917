import axiosInstance from '../axios/axiosInstance';
import { LoginRequest } from '../pages/Login/type/LoginTypes';

export const getProfile = () => {
  return axiosInstance.get('/v1/auth/my-profile');
};

export const login = (data: LoginRequest) => {
  return axiosInstance.post('/v1/auth/login', data);
};
