import { useMemo } from 'react';
import { DEFAULT_PAGINATE_SIZE } from '../constants/common';

interface PaginationConfig {
  onChange: (pageNumber: number, pageSize?: number) => void;
  showSizeChanger: boolean;
  total: number;
  current: number;
  pageSize?: number;
}

const usePaginationConfig = (
  total: number,
  page: number,
  setPage: (page: number) => void,
  pageSize: number = DEFAULT_PAGINATE_SIZE
): PaginationConfig => {
  const paginationConfig = useMemo(
    () => ({
      defaultPageSize: pageSize,
      onChange: (pageNumber: number) => {
        setPage(pageNumber);
      },
      showSizeChanger: false,
      total,
      current: page,
      showTotal: (total: number) => `Total ${total} items`
    }),
    [total, page, setPage]
  );

  return paginationConfig;
};

export default usePaginationConfig;
