import React from 'react';
import Sider from 'antd/es/layout/Sider';
import { Menu } from 'antd';
import { TwitchOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
const SiderComponent: React.FC = () => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth
  );

  const { faceBookUserInformation } = useSelector(
    (state: RootState) => state.facebookUserInformation
  );

  if (!isAuthenticated) {
    return null;
  }

  console.log(user?.roles[0].role_tag === 'page_manager', '123abc');

  return (
    <Sider collapsible className="h-full overflow-auto">
      <div className="demo-logo-vertical" />
      <Menu
        className="m-3"
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1-1']}
        defaultOpenKeys={['sub1', 'sub2', 'sub3']}
      >
        {user?.roles[0].role_tag === 'page_manager' && (
          <Menu.SubMenu key="sub1" icon={<TwitchOutlined />} title="Facebook">
            <Menu.Item key="1-1" icon={<UnorderedListOutlined />}>
              <Link to="/main">Connection</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {user?.roles[0].role_tag !== 'page_manager' && (
          <Menu.SubMenu key="sub2" icon={<TwitchOutlined />} title="Account">
            <Menu.Item key="2-1" icon={<UnorderedListOutlined />}>
              <Link to="/account">List</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {faceBookUserInformation?.id &&
          user?.roles[0].role_tag === 'page_manager' && (
            <Menu.SubMenu key="sub3" icon={<TwitchOutlined />} title="Pages">
              <Menu.Item key="3-1" icon={<UnorderedListOutlined />}>
                <Link to="/facebook-page">List</Link>
              </Menu.Item>
            </Menu.SubMenu>
          )}
      </Menu>
    </Sider>
  );
};

export default SiderComponent;
