import React, { memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface GuardRouteProps {
  element: React.ReactElement;
  hasPermission?: boolean;
}

const GuardRoute: React.FC<GuardRouteProps> = memo(
  ({ element, hasPermission = true }) => {
    const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated
    );

    const location = useLocation();

    return isAuthenticated && hasPermission ? (
      element
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
);

GuardRoute.displayName = 'GuardRoute';

export default GuardRoute;
