import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginComponent from '../pages/Login/LoginComponent';
import PublicRoute from './PublicRoute';
import MainComponent from '../pages/MainComponent/MainComponent';
import GuardRoute from './GuardRoute';
import UserManageComponet from '../pages/UserManage/UserManage';
import FacebookPageManageComponent from '../pages/FaceBookPageManage/FacebookPageManage';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const AppRoutersComponent = () => {
  const { user, loading, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  if (loading || (!user && isAuthenticated)) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={<PublicRoute element={<LoginComponent />} />}
      />
      <Route
        path="/main"
        element={<GuardRoute element={<MainComponent />} />}
      />
      <Route
        path="/account"
        element={<GuardRoute element={<UserManageComponet />} />}
      />
      <Route
        path="/facebook-page"
        element={<GuardRoute element={<FacebookPageManageComponent />} />}
      />
      <Route
        path="*"
        element={
          <GuardRoute
            element={
              user?.roles[0].role_tag === 'page_manager' ? (
                <MainComponent />
              ) : (
                <UserManageComponet />
              )
            }
          />
        }
      />
    </Routes>
  );
};

export default AppRoutersComponent;
