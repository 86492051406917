import axiosInstance from '../axios/axiosInstance';

export const getAccounts = (params: any) => {
  return axiosInstance.get('/v1/account-managements', {
    params
  });
};

export const createAccount = (data: any) => {
  return axiosInstance.post('/v1/account-managements', data);
};

export const deleteAccount = (id: any) => {
  return axiosInstance.delete(`/v1/account-managements/${id}`);
};

export const activeAccount = (id: any) => {
  return axiosInstance.put(`/v1/account-managements/account/${id}/activate`);
};

export const deactiveAccount = (id: any) => {
  return axiosInstance.put(`/v1/account-managements/${id}/deactivate`);
};
