import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';

interface LoadingComponentProps {
  loading: boolean;
  fullscreen?: boolean;
  size?: number;
  children?: ReactNode;
  text?: string;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({
  children,
  loading,
  size = 40,
  text = '',
  fullscreen = false
}) => {
  const indicator = <LoadingOutlined style={{ fontSize: size }} spin />;
  return (
    <Spin
      spinning={loading}
      indicator={indicator}
      tip={text}
      fullscreen={fullscreen}
    >
      {children}
    </Spin>
  );
};

export default LoadingComponent;
